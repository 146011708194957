var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-wrap"},[_c('h4',{staticClass:"title-h4"},[_vm._v("我的优惠券")]),_c('div',{staticClass:"tabs"},_vm._l((_vm.tabMenu),function(item,index){return _c('p',{key:index,staticClass:"tabItem",class:{ active: item.code === _vm.tabCode },on:{"click":function($event){return _vm.tabChange(item.code)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"couponList"},_vm._l((_vm.couponList),function(item,index){return _c('div',{key:index,staticClass:"couponItem",class:item.state == 4?'used':item.state == 3?'overdue':''},[_c('div',{class:{'origin-layer' : _vm.tabCode == 1}},[(item.type == 1)?[_c('p',{staticClass:"price"},[_vm._v("¥"),_c('span',{style:([
              {
                fontSize:(item.couponPrice+'').length < 4?'64px':(item.couponPrice+'').length == 4?'55px':'40px'
              },
              {
                lineHeight:(item.couponPrice+'').length <= 4?'77px':'77px'
              }
            ])},[_vm._v(_vm._s(item.couponPrice))])]),_c('p',{staticClass:"condition"},[_vm._v("满"+_vm._s(item.fullPrice)+"元可用")])]:_vm._e(),(item.type == 3)?[_c('p',{staticClass:"price"},[_c('span',[_vm._v(_vm._s(item.discount))]),_vm._v("折")]),_c('p',{staticClass:"condition"},[_vm._v("满"+_vm._s(item.discountPrice)+_vm._s(item.discountType==1?'件':'元')+"可用")])]:_vm._e(),_c('p',{staticClass:"type"},[_vm._v("「"+_vm._s(item.receiveDesc)+"」")]),(item.times == 2 || item.times == 3)?_c('p',{staticClass:"time"},[_vm._v("有效期至"+_vm._s(item.lapseTime))]):_vm._e(),(item.times == 1)?_c('p',{staticClass:"time"},[_vm._v("不限使用时间")]):_vm._e(),(item.state == 3)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/order/overdueIcon.png"),"alt":""}}):_vm._e(),(item.state == 4)?_c('img',{staticClass:"icon",attrs:{"src":require("@/assets/image/order/usedIcon.png"),"alt":""}}):_vm._e()],2),(_vm.tabCode == 1)?_c('div',{staticClass:"mask-layer"},[_c('span',{staticClass:"btn",on:{"click":function($event){return _vm.goCouponDetail(item)}}},[_vm._v("可用商品")])]):_vm._e()])}),0),_c('a-spin',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"load",attrs:{"size":"large"}}),(!_vm.loading && !_vm.couponList.length)?_c('no-message',{attrs:{"msg":'暂无优惠券',"imgSrc":require('@/assets/image/personalCenter/noCoupon.png')}}):_vm._e(),_c('a-pagination',{attrs:{"hideOnSinglePage":true,"pageSize":_vm.pageSize,"default-current":6,"total":_vm.total},on:{"change":_vm.onChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }